import React from 'react'
import { UncTechnicalSpecifications } from '@root-gipro/modules/commonProjects/models/interfaces/unc-technical-specifications.model'
import { createUncTechnicalSpecificationsArr } from '@root-gipro/modules/userProjects/utils/helpers'
export const titleDataTechnical = [
	'Оборудование',
	'Машины и механизмы',
	'Основные работы',
	'Материалы',
	'Затраты труда рабочих-строителей и машинистов',
]
export const tbodyArr = [
	{
		tableNum: "equipment",
		technicalSpecifications: (resourceType: number, technicalSpecifications: UncTechnicalSpecifications[]) => createUncTechnicalSpecificationsArr(resourceType, technicalSpecifications),
		title: 'Оборудование - equipment',
		sitePreparation: true,
	},
	{
		tableNum: "machine",
		technicalSpecifications: (resourceType: number, technicalSpecifications: UncTechnicalSpecifications[]) => createUncTechnicalSpecificationsArr(resourceType, technicalSpecifications),
		title: 'Машины и механизмы - machine',
		sitePreparation: true,
	},
	{
		tableNum: "mainWork",
		technicalSpecifications: (resourceType: number, technicalSpecifications: UncTechnicalSpecifications[]) => createUncTechnicalSpecificationsArr(resourceType, technicalSpecifications),
		title: 'Основные работы - mainWork',
		sitePreparation: true,
	},
	{
		tableNum: "material",
		technicalSpecifications: (resourceType: number, technicalSpecifications: UncTechnicalSpecifications[]) => createUncTechnicalSpecificationsArr(resourceType, technicalSpecifications),
		title: 'Материалы - material',
		sitePreparation: true,
	},
	{
		tableNum: "material",
		technicalSpecifications: (resourceType: number, technicalSpecifications: UncTechnicalSpecifications[]) => createUncTechnicalSpecificationsArr(resourceType, technicalSpecifications),
		title: 'Затраты труда рабочих-строителей и машинистов - worker',
		sitePreparation: true,
	},
	
]
export const cellData = (getNumberUnc: (id: string) => number | string) => [
	{
		value: (value: UncTechnicalSpecifications, index?: number) => index !== undefined && index + 1,
		textAlign: 'textAlignRight',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.name,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.formattedParams,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.voltage,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.quantity,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => value.unit,
		textAlign: 'textAlignLeft',
	},
	{
		value: (value: UncTechnicalSpecifications) => Math.floor(value.cost/1000),
		textAlign: 'textAlignLeft',
	},
]
