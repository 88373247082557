import { UncTechnicalSpecifications } from '@root-gipro/modules/commonProjects/models/interfaces/unc-technical-specifications.model'
import { IUnc } from '@root-gipro/store/interfaces'

export const createUncArr = (tableNum: number, uncs: IUnc[]) => {
	let arr: IUnc[] = []
	if (uncs && uncs.length > 0) {
		arr = uncs.filter((unc: IUnc) => unc.tableNum === tableNum)
	}

	return arr
}
export const createUncTechnicalSpecificationsArr = (resourceType: number, technicalSpecifications: UncTechnicalSpecifications[]) => {
	let arr: UncTechnicalSpecifications[] = []
	if (technicalSpecifications && technicalSpecifications.length > 0) {
		arr = technicalSpecifications.filter((thSpec: UncTechnicalSpecifications) => thSpec.resourceType === resourceType)
	}

	return arr
}

export function addActiveUnc(activeUncs: IUnc[], unc: IUnc) {
	let index = activeUncs.findIndex(active => active.id === unc.id),
		clone = activeUncs.slice()
	if (index === -1) {
		clone.push(unc)
	} else {
		clone.splice(index, 1)
	}
	return { clone, index }
}

export function getTargetVoltageValues(buffer: any, id: any) {
	const index = buffer.findIndex((item: any) => {
		return item.id === id
	})
	if (index >= 0) {
		return buffer[index]['targetVoltageValues']
	}
}
export const filterWidthWindow = (step: string | undefined) => {
	switch (step) {
		case 'error-list':
			return '360px'
		case 'check-list':
			return '240px'
		default:
			break
	}
}

export const formatedDates = (stringDates: string) => {
	const date = new Date(stringDates)
	const month = date.getMonth() + 1
	const day = date.getDate()
	const year = date.getFullYear()
	return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
}
