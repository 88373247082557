import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { Telegram, WhatsApp, Link as LinkIcon } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { showNotify } from '@root-gipro/modules/notify/store/actions'

interface ISharedDialog {
  open: boolean
  setOpen: (mode: boolean) => void
}

const SharedDialog: React.FC<ISharedDialog> = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const handleClose = () => setOpen(false)

  const shareTo = (platform: 'telegram' | 'vk' | 'whatsapp') => {
    const url = encodeURIComponent(window.location.href)
    let shareUrl = ''

    switch (platform) {
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${url}`
        break
      case 'vk':
        shareUrl = `https://vk.com/share.php?url=${url}`
        break
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${url}`
        break
    }

    window.open(shareUrl, '_blank')
    handleClose()
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
    dispatch(
      showNotify({
        type: 'success',
        message: 'Скопировано в буфер обмена',
        duration: 2000,
      })
    )
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{textAlign: 'center'}}>Поделиться</DialogTitle>
      <DialogContent style={{width: '350px'}}>
        <List>
          <ListItem button onClick={() => shareTo('telegram')}>
            <ListItemIcon>
              <Telegram color="primary" />
            </ListItemIcon>
            <ListItemText primary="Поделиться в Telegram" />
          </ListItem>
          <ListItem button onClick={() => shareTo('vk')}>
            <ListItemIcon>
              <img src="/assets/images/vk_icon.png" alt="VK" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Поделиться в VK" />
          </ListItem>
          <ListItem button onClick={() => shareTo('whatsapp')}>
            <ListItemIcon>
              <WhatsApp color="primary" />
            </ListItemIcon>
            <ListItemText primary="Поделиться в WhatsApp" />
          </ListItem>
          <ListItem button onClick={copyToClipboard}>
            <ListItemIcon>
              <LinkIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Скопировать ссылку" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SharedDialog
