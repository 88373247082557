import React from 'react'
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { FilterItemCheckboxProps } from '@root-gipro/shared/Filter/models/filter-item-checkbox.props'
import clsx from 'clsx'

const classesFormControlLabelStyle = makeStyles(theme => ({
	root: {
		padding: '0 4px 4px',
		marginLeft: 0,
	},
	label: {
		fontSize: theme.typography.pxToRem(14),
		letterSpacing: '0.03333em',
		lineHeight: 1.3,
	},
}))

const classesCheckboxStyle = makeStyles(theme => ({
	root: {
		padding: '0',
		marginRight: 8,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#fff',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.0)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '##fff',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(255,255,255,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#1baaf0',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#1baaf0',
		},
	},
}))

const FilterItemCheckbox: React.FC<FilterItemCheckboxProps> = ({
	value,
	setValue,
	name,
	isDisable,
	fieldName,
	isSiprChecked,
}) => {
	const classes = classesFormControlLabelStyle()
	const classesCheckbox = classesCheckboxStyle()

	return (
		<div className='aside-filter__checkbox'>
			<FormControlLabel
				control={
					<Checkbox
						disabled={isDisable}
						checked={name === 'СиПР' ? isSiprChecked : Boolean(value)}
						onChange={() => setValue(fieldName, !Boolean(value))}
						color='primary'
						size='small'
						className={classesCheckbox.root}
						icon={<span className={classesCheckbox.icon} />}
						checkedIcon={<span className={clsx(classesCheckbox.icon, classesCheckbox.checkedIcon)} />}
					/>
				}
				label={name}
				classes={classes}
			/>
		</div>
	)
}

export default FilterItemCheckbox
