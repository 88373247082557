import React, { useContext, MouseEvent, FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CardHeaderTitleProps } from '@root-gipro/shared/Card/CardHeaderTitle/card-header-title.props'
import { ModalContext } from '@root-gipro/context/modal-context'
import { CostWorksSVG } from '@root-gipro/shared/SVGComponents/CostWorksSVG'
import { StarSVG } from '@root-gipro/shared/SVGComponents/StarSVG'
import { LingSVG } from '@root-gipro/shared/SVGComponents/LinkSVG'
import { Alert } from '@material-ui/lab'
import { ProjectType } from '@root-gipro/modules/favorites/models/enums/projects.enum'
import { deleteFavoritesProjects } from '@root-gipro/modules/favorites/store/actions'
import { useDispatch } from 'react-redux'
import { setTargetProjectId } from '@root-gipro/modules/commonProjects/store/actions'
import SearchIcon from '@material-ui/icons/Search'
import { IconButton, Tooltip } from '@material-ui/core'
import { setSimilarProjectSearch } from '@root-gipro/modules/filter/store/actions'
import { PassportSVG } from '@root-gipro/shared/SVGComponents/PassporstSVG'
const CardHeaderTitle: FC<CardHeaderTitleProps> = ({
	commonProjectId,
	title,
	projectName,
	iconType,
	toggleType,
	expandCardHandler,
	isExpanded,
	path,
	children,
	getProjectById,
	purchaseId,
	isFavorite,
	handlerFavoritesActions,
	handlerLinkClick,
	url_public,
	isActual,
	project,
}) => {
	const { setAnchorEl } = useContext(ModalContext)
	const dispatch = useDispatch()
	let location = useLocation()
	const url: string = location.pathname.slice(1)
	const [showAlert, setShowAlert] = useState(false)
	const [isFavoriteLocal, setIsFavoriteLocal] = useState(isFavorite)
	const role = Number(window.localStorage.getItem('roleId'))
	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		setAnchorEl!(event.currentTarget)
	}

	const [currUrl, setCurrUrl] = useState<null | string>(null)
	const projectId = purchaseId || commonProjectId
	let classes

	if (!toggleType) toggleType = 'PROJECTS'

	if (projectId) {
		classes = 'space-between'
	} else {
		classes = 'flex-end'
	}
	useEffect(() => {
		setCurrUrl(url)
	}, [url])

	const handleBtn = (id: string) => {
		getProjectById && getProjectById(id)
		dispatch(setTargetProjectId(id))
	}

	return (
		<>
			<div className='card-container__head'>
				{path && role != 999 ? (
					<Link to={path} onClick={() => handleBtn(projectId as string)} className={`card-container__code link-blue`}>
						<span className={iconType} />
						<span className='code'>{title}</span>
					</Link>
				) : (
					<div className={`card-container__code link-blue`}>
						{iconType && <span className={iconType} />}
						<span
							className={`${iconType && 'code'}`}
							onClick={() => {
								if (role && role == 999) {
									setShowAlert(true)
								} else {
									getProjectById && getProjectById(projectId as string)
								}
							}}
						>
							{title}
						</span>
					</div>
				)}

				<div className={`card-container__wrapper-actions ${classes}`}>
					<div className='card-container__actions'>
						<span
							className='card-container__detailed-btn'
							onClick={() => projectId && expandCardHandler && expandCardHandler(projectId)}
						>
							{isExpanded ? 'Свернуть' : 'Подробнее'}
						</span>
					</div>

					{projectId && (
						<>
							<div className='card-container__actions-icons flex'>
								{commonProjectId && (
									<>
										<div
											className='icon-wrapper'
											data-popup='costWorks'
											onClick={(event: MouseEvent<HTMLDivElement>) => handleClick(event)}
										>
											<CostWorksSVG classes='cost-works-icon' />
										</div>
									</>
								)}
								{purchaseId && (
									<div className='icon-wrapper' onClick={() => window.open(url_public)}>
										<LingSVG className='link-icon' />
									</div>
								)}

								{!purchaseId && (
									<div
										className={`${isFavoriteLocal ? 'icon-wrapper-favorite' : 'icon-wrapper'}`}
										data-popup='costWorks'
										onClick={() => {
											if (role && role == 999) {
												setShowAlert(true)
											} else {
												if (isFavoriteLocal) {
													setIsFavoriteLocal(false)
													if (toggleType === ProjectType.PROJECTS) {
														handlerFavoritesActions(projectId, 'DELETE', toggleType)
													} else if (toggleType === ProjectType.PURCHASES) {
														dispatch(deleteFavoritesProjects(projectId, toggleType))
													}
												} else {
													setIsFavoriteLocal(true)
													handlerFavoritesActions(projectId, 'POST', toggleType)
												}
											}
										}}
									>
										<StarSVG classes='cost-works-icon' chosen={isFavoriteLocal} />
									</div>
								)}
								{commonProjectId && (
									<Tooltip title={!project?.passportUrl ? 'Паспорт отсутствует' : 'Скачать паспорт'}>
										<span>
											<IconButton
												href={project?.passportUrl}
												download={`${project.name}-passport`}
												title={!project?.passportUrl ? 'Паспорт отсутствует' : 'Скачать паспорт'}
												type='button'
												style={{ padding: '5px', marginLeft: '7px' }}
												disabled={!project?.passportUrl}
											>
												<PassportSVG disabled={!project?.passportUrl} />
											</IconButton>
										</span>
									</Tooltip>
								)}
								{url === 'common-projects' && (
									<IconButton
										style={{ padding: '5px' }}
										type='button'
										onClick={() => commonProjectId && dispatch(setSimilarProjectSearch(commonProjectId, projectName))}
									>
										<SearchIcon color='primary' />
									</IconButton>
								)}
							</div>
						</>
					)}
				</div>
			</div>

			{showAlert === true && (
				<Alert severity='warning' action={<Link to='/tariffs'>Подключить</Link>}>
					Возможно только при подключении тарифа
				</Alert>
			)}
			{children}
		</>
	)
}

export default CardHeaderTitle
