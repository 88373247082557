import { all, call, put, takeEvery, takeLatest, fork, spawn } from 'redux-saga/effects'
import { fetchData } from '@root-gipro/store/api'
import {
	PublicType,
	setPurchasesMethods,
	setPurchasesStages,
	setPurchasesOkpd,
	setPurchasesOkved,
	setPublicInfo,
	countPublicInfoFetches,
} from '../actions/public'
import {
	getPurchaseMethods,
	getPurchaseStages,
	getPurchaseOkpd,
	getPurchaseOkved,
} from '@root-gipro/modules/purchases/api/purchases'
import { resolvePlugin } from '@babel/core'
import {
	ICompany,
	IGroup,
	IRegion,
	IRgStatuses,
	IUncMain,
	IUncThDescriptions,
	IUncThs,
} from '@root-gipro/store/interfaces'

function* handlePolicesGipro() {
	const policesGipro: boolean = yield call(
		fetchData,
		'/policies?action=.userProject&kind=self',
		(res: any) => res.policies['.userProject']['self']
	)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'policesGipro', infoData: policesGipro },
	})
	yield put(countPublicInfoFetches(true))
}

function* policesRGCO() {
	const policesGipro: boolean = yield call(
		fetchData,
		'/policies?action=rgco.ed.create&kind=all',
		(res: any) => res.policies['rgco.ed.create']['all']
	)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'policesGipro', infoData: policesGipro },
	})
	yield put(countPublicInfoFetches(true))
}

function* handleGroups() {
	const groups: IGroup[] = yield call(fetchData, '/groups', (res: any) => res.groups)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'groups', infoData: groups },
	})
	yield put(countPublicInfoFetches(true))
}

function* handleRegions() {
	const regions: IRegion[] = yield call(fetchData, '/regions', (res: any) => res.regions)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'regions', infoData: regions },
	})
	yield put(countPublicInfoFetches(true))
}

function* handleCompany() {
	const company: ICompany[] = yield call(fetchData, '/company', (res: any) => res.company)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'company', infoData: company },
	})
	yield put(countPublicInfoFetches(true))
}
function* handleUncMains() {
	const uncMains: IUncMain[] = yield call(fetchData, '/uncMains?order=code', (res: any) => res.uncMains)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'uncMains', infoData: uncMains },
	})
	yield put(countPublicInfoFetches(true))
}
function* handleUncCells() {
	const uncCells: IUncMain[] = yield call(fetchData, '/uncCells?with=uncTable', (res: any) => res.uncCells)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'uncCells', infoData: uncCells },
	})
	yield put(countPublicInfoFetches(true))
}
function* handlePublicationDates() {
	const publicationDates: string[] = yield call(fetchData, '/projects/values/publicationDate', (res: any) => res.values)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'publicationDates', infoData: publicationDates },
	})
	yield put(countPublicInfoFetches(true))
}
function* handlePurchasePublishDates() {
	const purchasePublishDates: string[] = yield call(
		fetchData,
		'/purchase/values/publish_date',
		(res: any) => res.values
	)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'purchasePublishDates', infoData: purchasePublishDates },
	})
	yield put(countPublicInfoFetches(true))
}
function* handleUncSections() {
	const uncSections: any[] = yield call(fetchData, '/unc-sections', (res: any) => res.uncSections)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'uncSections', infoData: uncSections },
	})
	yield put(countPublicInfoFetches(true))
}
function* handleUncThs() {
	const uncThs: IUncThs[] = yield call(fetchData, '/info/unc-ths', (res: any) => res.uncThs)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'uncThs', infoData: uncThs },
	})
	yield put(countPublicInfoFetches(true))
}
function* handleUncThDescriptions() {
	const uncThDescriptions: IUncThDescriptions[] = yield call(
		fetchData,
		'/info/unc-text-parameters',
		(res: any) => res.uncTextParameters
	)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'uncThDescriptions', infoData: uncThDescriptions },
	})
	yield put(countPublicInfoFetches(true))
}
function* handleRgStatuses() {
	const rgStatuses: IRgStatuses[] = yield call(fetchData, '/rg-statuses', (res: any) => res.rgStatuses)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'rgStatuses', infoData: rgStatuses },
	})
	yield put(countPublicInfoFetches(true))
}
function* handleMinMaxValues() {
	const minMax: [] = yield call(fetchData, '/projects/getMinMaxValues', (res: any) => res.min_max)
	yield put({
		type: PublicType.SET_PUBLIC_INFO,
		data: { infoName: 'minMax', infoData: minMax },
	})
	yield put(countPublicInfoFetches(true))
}

function* fetchPublicInfo() {
	yield fork(handlePolicesGipro)
	yield fork(policesRGCO)
	yield fork(handleGroups)
	yield fork(handleRegions)
	yield fork(handleCompany)
	yield fork(handleUncMains)
	yield fork(handleUncCells)
	yield fork(handleUncSections)
	yield fork(handleUncThs)
	yield fork(handleUncThDescriptions)
	yield fork(handlePurchasePublishDates)
	yield fork(handleRgStatuses)
	yield fork(handleMinMaxValues)
}

function* getPurchasesMethods() {
	try {
		const result: string[] = yield call(getPurchaseMethods)
		if (result) yield put(setPurchasesMethods(result))
	} catch (error) {}
}

function* getPurchasesStages() {
	try {
		const result: string[] = yield call(getPurchaseStages)
		if (result) yield put(setPurchasesStages(result))
	} catch (error) {}
}

function* getPurchasesOkpd() {
	try {
		const result: string[] = yield call(getPurchaseOkpd)
		if (result) yield put(setPurchasesOkpd(result))
	} catch (error) {}
}

function* getPurchasesOkved() {
	try {
		const result: string[] = yield call(getPurchaseOkved)
		if (result) yield put(setPurchasesOkved(result))
	} catch (error) {}
}

export default function* watchFetchPublicInfo() {
	yield takeLatest(PublicType.FETCH_PUBLIC_INFO, fetchPublicInfo)
	yield takeEvery(PublicType.GET_PURCHASES_METHODS, getPurchasesMethods)
	yield takeEvery(PublicType.GET_PURCHASES_STAGES, getPurchasesStages)
	yield takeEvery(PublicType.GET_PURCHASES_STAGES, getPurchasesOkpd)
	yield takeEvery(PublicType.GET_PURCHASES_STAGES, getPurchasesOkved)
}
