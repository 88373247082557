import UnnormalizedTable from '@root-gipro/modules/commonProjects/containers/SelectedProject/UnnormalizedTable'
import { getUnnormalizedCommonProjects } from '@root-gipro/modules/commonProjects/store/actions'
import { IState } from '@root-gipro/store/interfaces'
import { prettyCost } from '@root-gipro/utils/helpers/common'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import '@root-gipro/modules/commonProjects/styles/Rates.scss'

const UnnormalizedContainer: React.FC = () => {
	const dispatch = useDispatch()

	const { id } = useParams<{ id: string }>()
	useEffect(() => {
		dispatch(getUnnormalizedCommonProjects(id))
	}, [dispatch, id])
	const { unnormalizedData } = useSelector((state: IState) => state.commonProjects)

	const totalCost = unnormalizedData?.reduce((sum, obj) => sum + (obj.data.unn_costs || 0), 0)

	return (
		<div className='table_wrapper'>
			<div className='container'>
				<div className='unc_table__head'>
					<div className='unc_table__head-left'>
						<div className='head-left__unnormal' style={{ marginBottom: 16 }}>
							<div>Ненормируемые затраты </div>
							<div>{prettyCost(totalCost / 10 ** 3)} млн.р</div>
						</div>
					</div>
				</div>
				<UnnormalizedTable />
			</div>
		</div>
	)
}

export default UnnormalizedContainer
